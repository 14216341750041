import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const CustomToolbarSelect = ({ selectedRows, displayData, setSelectedRows, onDeleteBulk }) => {
  const handleDeleteClick = () => {
     // Obtén las filas seleccionadas del estado selectedRows
     const selectedRowsData = selectedRows.data.map((row) => row.index);

     // Llama a la función onDelete y pásale las filas seleccionadas
     onDeleteBulk(selectedRowsData);
     setSelectedRows([]);
     
  };

  return (
    <div>
      <IconButton onClick={handleDeleteClick}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

export default CustomToolbarSelect;
import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Badge,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Collapse, List
} from "@material-ui/core";

import {
  AccountTreeOutlined,
  ContactPhoneOutlined,
  DashboardOutlined,
  LocalOffer,
  PeopleAltOutlined,
  QuestionAnswerOutlined,
  SettingsOutlined,
  SyncAlt,
  WhatsApp,
  ExpandLess,
  ExpandMore,
  Code,
  MenuBook,
  VpnKeyRounded,
  Business
} from "@material-ui/icons";
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.secondary.main
  },
  li: {
    backgroundColor: theme.palette.menuItens.main
  },
  sub: {
    backgroundColor: theme.palette.sub.main
  },
  divider: {
    backgroundColor: theme.palette.divide.main
  }
}));

function ListItemLink(props) {
  const { icon, primary, to, className } = props;
  const classes = useStyles();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li className={classes.li}>
      <ListItem button component={renderLink} className={className}>
        {icon ? <ListItemIcon className={classes.icon}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const MainListItems = (props) => {
  const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const classes = useStyles();
  const [openAdminSubmenu, setOpenAdminSubmenu] = useState(false);
  const [openApiSubmenu, setOpenApiSubmenu] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <div onClick={drawerClose}>
      <ListItemLink to="/" primary="Dashboard" icon={<DashboardOutlined />} />
      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsApp />}
      />
      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactPhoneOutlined />}
      />
      <ListItemLink
        to="/quickAnswers"
        primary={i18n.t("mainDrawer.listItems.quickAnswers")}
        icon={<QuestionAnswerOutlined />}
      />
      <ListItemLink
        to="/tags"
        primary={i18n.t("mainDrawer.listItems.tags")}
        icon={<LocalOffer />}
      />
       <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={<EventAvailableOutlinedIcon />}
      />
      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider className={classes.divider} />
            <ListItem
        button
        onClick={() => setOpenAdminSubmenu((prev) => !prev)}
      >
        <ListItemIcon>
          <Business />
        </ListItemIcon>
        <ListItemText
          primary={i18n.t("mainDrawer.listItems.administration")}
        />
        {openAdminSubmenu ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItem>
      <Collapse
        style={{ paddingLeft: 15 }}
        in={openAdminSubmenu}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
        <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
                <Badge
                  badgeContent={connectionWarning ? "!" : 0}
                  color="error"
                  overlap="rectangular"
                >
                  <SyncAlt />
                </Badge>
              }
            />
            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<PeopleAltOutlined />}     
            />
            <ListItemLink
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              icon={<AccountTreeOutlined />}
            />
             <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsOutlined />}
            />
        </List>
      </Collapse>
      <ListItem
        button
        onClick={() => setOpenApiSubmenu((prev) => !prev)}
      >
        <ListItemIcon>
          <Code />
        </ListItemIcon>
        <ListItemText
          primary={i18n.t("mainDrawer.listItems.api")}
        />
        {openApiSubmenu ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItem>
      <Collapse
        style={{ paddingLeft: 15 }}
        in={openApiSubmenu}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
               <ListItemLink
              to="/api"
              primary={i18n.t("mainDrawer.listItems.apiUse")}
              icon={<MenuBook />}
            />
            <ListItemLink
              to="/apikey"
              primary={i18n.t("mainDrawer.listItems.apikey")}
              icon={<VpnKeyRounded />}
            />
        </List>
      </Collapse>
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;
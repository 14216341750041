import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import es from "date-fns/locale/es";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { AuthContext } from "../../context/Auth/AuthContext";

import useQueues from "../../hooks/useQueues";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
    marginTop: "10px",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ScheduleSchema = Yup.object().shape({
  body: Yup.string()
    .min(5, i18n.t("scheduleModal.form.msjcorto"))
    .required(i18n.t("scheduleModal.form.obligatorio")),
  contactId: Yup.number().required(i18n.t("scheduleModal.form.obligatorio")),
  sendAt: Yup.string().required(i18n.t("scheduleModal.form.obligatorio")),
});

const filterOptions = createFilterOptions({
  trim: true,
});

const ScheduleModal = ({
  open,
  onClose,
  scheduleId,
  contactId,
  cleanContact,
  reload,
}) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [settings, setSettings] = useState([]);
  const { findAll: findAllQueues } = useQueues();
  const { loadingWhatsapps, whatsApps } = useWhatsApps();
  //LOAD SETTINGS
  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data } = await api.get("/settings");
        setSettings(data);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, []);

  const getSettingValue = (key) => {
    const { value } = settings.find((s) => s.key === key);
    return value;
  };
  
  const cronjobStep =
    settings && settings.length > 0 && getSettingValue("timeCronJob");
  let step;
  switch (cronjobStep) {
    case "*/15 * * * *":
      step = 15;
      break;
    case "*/30 * * * *":
      step = 30;
      break;
    case "*/45 * * * *":
      step = 45;
      break;
    case "0 * * * *":
      step = 60;
      break;
    default:
      step = 15;
  }
  //DATEPICKER
  const DatePickerField = ({ field, form }) => {
    const { setFieldValue } = form;
    return (
      <DatePicker
        showTimeSelect
        timeIntervals={step}
        timeCaption="Tiempo"
        minDate={new Date()}
        popperPlacement="top-start"
        locale={es}
        dateFormat="MMMM d, yyyy h:mm aa"
        selected={field.value ? new Date(field.value) : null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      />
    );
  };

  //ESTADO INICIAL DEL FORMULARIO
  const initialState = {
    body: "",
    contactId: "",
    sendAt: moment().format("YYYY-MM-DDTHH:mm"),
    status: "Pendiente",
    queueId: null,
    userId: user.id,
    whatsappId:"",
  };

  const initialContact = {
    id: "",
    name: "",
  };
	const initialUser = {
    id: "",
    name: "",
  };
  const [schedule, setSchedule] = useState(initialState);
  //SELECT CONTACT
  const [currentContact, setCurrentContact] = useState(initialContact);
  const [contacts, setContacts] = useState([initialContact]);
  const [contactSearchParam, setContactSearchParam] = useState("");
  const [contactLoading, setContactLoading] = useState(false);
  //SELECT USER
  const [currentUser, setCurrentUser] = useState(initialUser);
  const [options, setOptions] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(false);
  //QUEUES
  const [selectedQueue, setSelectedQueue] = useState("");
  const [queues, setQueues] = useState([]);
  const [allQueues, setAllQueues] = useState([]);

  //Whatsapp
  const [selectedWhatsapp, setSelectedWhatsapp] = useState("");

 

  useEffect(() => {
    if (contactId && contacts.length) {
      const contact = contacts.find((c) => c.id === contactId);
      if (contact) {
        setCurrentContact(contact);
      }
    }
  }, [contactId, contacts]);

  useEffect(() => {
    const loadQueues = async () => {
      const list = await findAllQueues();
      setAllQueues(list);
      setQueues(list);
    };
    loadQueues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //CARGA DE ASISTENTES PARA EL AUTOCOMPLETE
  useEffect(() => {
    if (!open || searchParam.length < 2) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam },
          });
          setOptions(data.users);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, open]);

  //CARGA DE CONTACTOS PARA EL AUTOCOMPLETE
  useEffect(() => {
    if (!open || contactSearchParam.length < 2) {
      setContactLoading(false);
      return;
    }
    setContactLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } =   await api.get(`/contacts?searchParam=${contactSearchParam}`);
          
          /*TODO: Al usar de esta forma Error con Context en MenuList Actions, revisar por que ocurre.
          const { data } = await api.get("/contacts/", {
            params: { contactSearchParam },
          });*/

          setContacts(data.contacts);
          setContactLoading(false);
        } catch (err) {
          setContactLoading(false);
          toastError(err);
        }
      }; 

      fetchContacts();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [contactSearchParam, open]);


  //CUANDO ABRE LA MODAL
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          if (contactId) {
            setSchedule((prevState) => {
              return { ...prevState, contactId };
            });
          }
          if (!scheduleId) return;

          const { data } = await api.get(`/schedules/${scheduleId}`);
					const { data : currentUserArray } = await api.get("/users/" + data.userId);
          console.log(data);
          setSchedule((prevState) => {
            return {
              ...prevState,
              ...data,
              sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm"),
            };
          });
          if (data.queueId===null){setSelectedQueue("")}else{setSelectedQueue(data.queueId);}
          
          setCurrentContact(data.contact);
          
          setSelectedWhatsapp(data.whatsappId);

   
          const currentUser = { id: currentUserArray.id, name: currentUserArray.name };

          //const queueName = list.find(user => user.id === data.userId);
          //const currentQueue = (data.queueId);

          /*if(currentQueue){
            setCurrentQueue(currentQueue);
            console.log(currentQueue);
          }*/
					//const selectedUser = currentUserArray.find(user => user.id === data.userId);
					
					if (currentUser) {
						setCurrentUser(currentUser);
					}
        } catch (err) {
          toastError(err);
        }
      };

      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [scheduleId, contactId, open]);


  //FUNCION AL CERRAR LA MODAL
  const handleClose = () => {
    onClose();
    setSchedule(initialState);
    setSearchParam("");
    setContactSearchParam("");
    setSelectedQueue("");
    setSelectedWhatsapp("");
  };

  //FUNCION AL GUARDAR
  const handleSaveSchedule = async (values) => {
    const scheduleData = {
      ...values,
      status: "Pendiente",
    };
    try {
      if (scheduleId) {
        await api.put(`/schedules/${scheduleId}`, scheduleData);
        toast.success(i18n.t("scheduleModal.edit"));
      } else {
        await api.post("/schedules", scheduleData);
        console.log(scheduleData);
        toast.success(i18n.t("scheduleModal.success"));
      }
      if (typeof reload == "function") {
        reload();
      }
      if (contactId) {
        if (typeof cleanContact === "function") {
          cleanContact();
        }
      }
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {schedule.status === "ERRO" ? "Error" : `Recordatorio`}
        </DialogTitle>
        <Formik
          initialValues={schedule}
          enableReinitialize={true}
          validationSchema={ScheduleSchema}
          onSubmit={(values, actions) => {
            //console.log('saved:', JSON.parse(JSON.stringify(values)) );

            handleSaveSchedule(values);
            actions.setSubmitting(false);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form>
              <DialogContent dividers>
              

              <div className={classes.multFieldLine}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      name="contacto"
                      fullWidth
                      getOptionLabel={(option) => `${option.name}`}
                      onChange={(e, newValue) => {
                        const contactId = newValue ? newValue.id : "";
                        setSchedule({ ...schedule, contactId });
                      }}
                      options={contacts}
                      filterOptions={filterOptions}
                      freeSolo
                      autoHighlight
                      noOptionsText={i18n.t("transferTicketModal.noOptions")}
                      loading={contactLoading}
											value={currentContact}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={i18n.t("newTicketModal.fieldLabel")}
                          variant="outlined"
                          onChange={(e) => setContactSearchParam(e.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {contactLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <div className={classes.multFieldLine}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      name="asistente"
                      fullWidth
                      getOptionLabel={(option) => `${option.name}`}
                      onChange={(e, newValue) => {
                        const userId = newValue ? newValue.id : user.id;
                        setSchedule({ ...schedule, userId });
                        if (
                          newValue != null &&
                          Array.isArray(newValue.queues)
                        ) {
                          setQueues(newValue.queues);
                        } else {
                          setQueues(allQueues);
                          setSelectedQueue("");
                        }
                      }}
                      options={options}
                      filterOptions={filterOptions}
                      freeSolo
                      autoHighlight
                      noOptionsText={i18n.t("transferTicketModal.noOptions")}
                      loading={loading}
											value={currentUser}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={i18n.t("transferTicketModal.fieldLabel")}
                          variant="outlined"
                          onChange={(e) => setSearchParam(e.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>

          
                <div className={classes.multFieldLine}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>
                      {i18n.t("scheduleModal.form.queueId")}
                    </InputLabel>
                    <Select
                      fullWidth
                      value={selectedQueue}
                     onChange={(e) => {
                        let queueId = e.target.value !== "" ? e.target.value : null;
                        setSelectedQueue(e.target.value ? e.target.value : "");
                        setSchedule((prevState) => {
                          return { ...prevState, queueId };
                        });
                      }}
                      label={i18n.t(
                        "scheduleModal.form.queueId"
                      )}
                    >
                      <MenuItem value={null}>&nbsp;</MenuItem>
                      {queues.map((queue) => (
                        <MenuItem key={queue.id} value={queue.id}>
                          {queue.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <br />
                <div className={classes.multFieldLine}>
                  {!loadingWhatsapps && (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>
                        {i18n.t("scheduleModal.form.conection")}
                      </InputLabel>
                      <Select
                        fullWidth
                        value={selectedWhatsapp}
                        onChange={(e) => {
                          let whatsappId = e.target.value;
                          setSelectedWhatsapp(whatsappId);
                          setSchedule((prevState) => {
                            return { ...prevState, whatsappId };
                          });
                        }}
                        label={i18n.t(
                          "scheduleModal.form.conection"
                        )}
                      > <MenuItem value={null}></MenuItem>
                        {
                        whatsApps.map((whasapp) => (
                          <MenuItem key={whasapp.id} value={whasapp.id}>
                            {whasapp.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    minRows={4}
                    multiline={true}
                    label={i18n.t("scheduleModal.form.body")}
                    name="body"
                    error={touched.body && Boolean(errors.body)}
                    helperText={touched.body && errors.body}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <Field name="sendAt">
                    {({ field, form }) => (
                      <DatePickerField field={field} form={form} />
                    )}
                  </Field>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("scheduleModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {scheduleId
                    ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                    : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ScheduleModal;

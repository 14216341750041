import api from "../../services/api";

const useBitacora = () => {
  const sendTransferMessage = async (
    ticketid,
    interationCode,
    loggedInUser,
    selectedUser = null,
    interaction = ""
  ) => {
    try {
      const transferMessage = {
        read: 1,
        fromMe: true,
        body: "",
        isPrivate: true,
        ack: interationCode,
      };
      if (interationCode === 5) {
        //Transferido
        transferMessage.body = `Transferido por: ${loggedInUser} a ${selectedUser}`;
      }
      if (interationCode === 8) {
        //Enviado a pendiente
        transferMessage.body = `Enviado a espera por: ${loggedInUser}`;
      }
      if (interationCode === 9) {
        //El usuario vio el ticket sin aceptarlo.
        transferMessage.body = `Visto por: ${loggedInUser}`;
      }
      if (interationCode === 6) {
        //Cerrado
        if (interaction !== "") {
          transferMessage.body = `Resuelto por: ${loggedInUser}`;
        } else {
          transferMessage.body = `Cerrado por: ${loggedInUser}`;
        }
      }
      if (interationCode === 7) {
        //Abierto
        if (interaction !== "") {
          transferMessage.body = `Reabierto por: ${loggedInUser}`;
        } else {
          transferMessage.body = `Abierto por: ${loggedInUser}`;
        }
      }
      await api.post(`/messages/${ticketid}`, transferMessage);

      // No hay necesidad de manejar loading aquí, ya que el componente que utilice este Hook puede manejarlo según sus necesidades.
    } catch (error) {
      throw error; // Se puede manejar el error aquí o simplemente lanzarlo para que lo manejen en el componente que utilice este Hook.
    }
  };

  return {
    sendTransferMessage,
  };
};

export default useBitacora;

import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext,
} from "react";
import { toast } from "react-toastify";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import ScheduleModal from "../../components/ScheduleModal";

import ConfirmationModal from "../../components/ConfirmationModal";

import DataTable from "../../components/DataTable";

import toastError from "../../errors/toastError";

import openSocket from "../../services/socket-io";

import { AuthContext } from "../../context/Auth/AuthContext";

// A custom hook that builds on useLocation to parse
// the query string for you.
const getUrlParam = (param) => {
  return new URLSearchParams(window.location.search).get(param);
};

const reducer = (state, action) => {
  if (action.type === "LOAD_SCHEDULES") {
    const schedules = action.payload;
    const newSchedules = [];

    schedules.forEach((schedule) => {
      const scheduleIndex = state.findIndex((s) => s.id === schedule.id);
      if (scheduleIndex !== -1) {
        state[scheduleIndex] = schedule;
      } else {
        newSchedules.push(schedule);
      }
    });

    return [...state, ...newSchedules];
  }

  if (action.type === "UPDATE_SCHEDULES") {
    const schedule = action.payload;
    const scheduleIndex = state.findIndex((s) => s.id === schedule.id);

    if (scheduleIndex !== -1) {
      state[scheduleIndex] = schedule;
      return [...state];
    } else {
      return [schedule, ...state];
    }
  }

  if (action.type === "DELETE_SCHEDULE") {
    const scheduleId = action.payload;

    const scheduleIndex = state.findIndex((s) => s.id === scheduleId);
    if (scheduleIndex !== -1) {
      state.splice(scheduleIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Schedules = () => {

  const { user } = useContext(AuthContext);

  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [deletingSchedule, setDeletingSchedule] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [schedules, dispatch] = useReducer(reducer, []);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [contactId, setContactId] = useState(+getUrlParam("contactId"));

  const fetchSchedules = useCallback(async () => {
    try {
      const { data } = await api.get("/schedules/");
      dispatch({ type: "LOAD_SCHEDULES", payload: data.schedules });
      
    } catch (err) {
      toastError(err);
    }
  }, []);

  const handleOpenScheduleModalFromContactId = useCallback(() => {
    if (contactId) {
      handleOpenScheduleModal();
    }
  }, [contactId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchSchedules();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [
    contactId,
    fetchSchedules,
    handleOpenScheduleModalFromContactId,
  ]);

  useEffect(() => {
    handleOpenScheduleModalFromContactId();
    const socket = openSocket();

    socket.on("user", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_SCHEDULES", payload: data.schedules });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_SCHEDULE", payload: +data.scheduleId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [handleOpenScheduleModalFromContactId, user]);

  const cleanContact = () => {
    setContactId("");
  };

  const handleDeleteBulkSchedules = async (selectedRowsData) => {
    const idsToDelete = selectedRowsData.map((selectedIndex) => schedules[selectedIndex].id);

    // Llama a la función handleDeleteSchedule para eliminar las filas seleccionadas
    for (const idToDelete of idsToDelete) {
      await handleDeleteSchedule(idToDelete);
    }
    setDeletingSchedule(null);
    dispatch({ type: "RESET" });
    await fetchSchedules();
  };

  const handleOpenScheduleModal = () => {
    setSelectedSchedule(null);
    setScheduleModalOpen(true);
  };

  const handleCloseScheduleModal = () => {
    setSelectedSchedule(null);
    setScheduleModalOpen(false);
  };

  const handleEditSchedule = (schedule) => {
    setSelectedSchedule(schedule);
    setScheduleModalOpen(true);
  };

  const handleDeleteSchedule = async (scheduleId) => {
    try {
      await api.delete(`/schedules/${scheduleId}`);
      toast.success(i18n.t("schedules.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingSchedule(null);
    dispatch({ type: "RESET" });
    await fetchSchedules();
  };

  const columns = [
    {
      name:"id",
      label:"id"
    },
    {
      name:"contact",
      label:i18n.t("schedules.table.client")
    },
    {
      name:"body",
      label:i18n.t("schedules.table.body")
    },
    {
      name:"date",
      label: i18n.t("schedules.table.date")
    },
    {
      name:"status",
      label: i18n.t("schedules.table.status")
    },
  ];
  return (
    <div>      
    <ConfirmationModal
        title={
          deletingSchedule &&
          `${i18n.t("schedules.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteSchedule(deletingSchedule.id)}
      >
        {i18n.t("schedules.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ScheduleModal
        open={scheduleModalOpen}
        onClose={handleCloseScheduleModal}
        reload={fetchSchedules}
        aria-labelledby="form-dialog-title"
        scheduleId={selectedSchedule && selectedSchedule.id}
        contactId={contactId}
        cleanContact={cleanContact}
      />
     
    <DataTable 
    title={i18n.t("schedules.title")}
    datacells={schedules} 
    columns={columns}
    responseMap="schedule"//No lo uso por ahora.
    dateValue="date"
    bodyValue="body"
    contactValue="contact"
    onAdd={handleOpenScheduleModal}
    onEdit = {handleEditSchedule}
    onDeleteBulk = {handleDeleteBulkSchedules}
    onDelete={(schedule) => {
      setConfirmModalOpen(true);
      setDeletingSchedule(schedule);
    }}  
    showFilter={false} 
    print={false} 
    downloadCSV={false} 
    lens = {null} //Si no existe el valor por defecto es 10.
    rowsPerPage ={10} //Si no existe el valor por defecto es 10.
    />
    </div>
  );
};

export default Schedules;

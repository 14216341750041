import React from "react";
import { useHistory } from 'react-router-dom'; 
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import IconButton  from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import ChatIcon from '@material-ui/icons/Chat';
import CustomToolbarSelect from './CustomToolbarSelect'; 
import moment from "moment";

const darkTheme = createTheme({
  palette: {
    type: 'light',
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    position: "relative",
    overflow: "hidden",
    marginTop: "12px",
    marginBotton: "12px",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
}));
    const DataTable = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const {
      datacells, columns, title, onEdit, onAdd, onDelete, showFilter, print, downloadCSV, rowsPerPage, onDeleteBulk, lens, dateValue, bodyValue, contactValue
    } = props;

    const truncate = (str, len) => {
      if (str.length > len) {
        return str.substring(0, len) + "...";
      }
      return str;
    };

    //Formatear los datos para luego mostrarlos en las colunas deseadas, los paso por atributos del componentes le indco que columna corresponde.
    //y se mapea el datacells a continuacion.
    const truncatedData = datacells.map(responseMap => {
      const formattedDate = moment(responseMap.sendAt).format("DD/MM/YYYY HH:mm:ss");
      const bodyTruncate = truncate(responseMap.body, lens ?? 10);
      const contactNameFind = responseMap.contact.name;
      return {
      ...responseMap,
      [dateValue]: formattedDate,
      [bodyValue]: bodyTruncate,
      [contactValue]:contactNameFind
    }
    });
    const goto = (ticketId) => {
      const customUrl = `/tickets/${ticketId}`;
      // Redirigir a la URL personalizada
      history.push(customUrl);
    };
    //ocultar la columna ID para que sea el checkbox.
    const col = [
      ...columns.slice(1),
      {
        name: "",
        options: {
          customBodyRender: (value, tableMeta, updateValue, rowData) => {
         //forma de array   const id = datacells[tableMeta.rowIndex][0];
        // forma de objeto   const id = datacells[tableMeta.rowIndex].id;
           
            const row = datacells[tableMeta.rowIndex];
            const id = datacells[tableMeta.rowIndex].ticketId;
            const status = datacells[tableMeta.rowIndex].status;

            //const ticketId = rowData.ticketId;
            return (
              <div>
                <IconButton onClick={() => onEdit(row)}>
                  <EditIcon />
                </IconButton>

                {status!=="Pendiente" &&   
                <IconButton onClick={() => goto(id)}>
                  <ChatIcon />
                </IconButton>
                }
              
                <IconButton onClick={() => onDelete(row)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            );
          },
        },
      },
    ];
    const data = truncatedData;
    const options = { 
      filter: showFilter,
      filterType: 'checkbox',
      selectableRows:'multiple',
      print: print, // Mostrar o no el botón de imprimir
      download: downloadCSV, // Mostrar o no el botón de descargar en formato CSV
      rowsPerPage: rowsPerPage || 10,
      customToolbar: () => (
        <span>
          <IconButton onClick={() => onAdd()}>
            <AddIcon />
          </IconButton>
          {/* Botón de búsqueda predeterminado */}
        </span>
      ),
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          onDeleteBulk={onDeleteBulk}  // Esta es la función que se ejecutará al presionar el botón de eliminar
        />
      ),
      //TODO Agregar traducciones al datatable.
      textLabels: {
        body: {
          noMatch: "No se encontraron registros",
          toolTip: "Ordenar",
          columnHeaderTooltip: column => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente",
          previous: "Anterior",
          rowsPerPage: "Filas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver Columnas",
          filterTable: "Filtrar Tabla",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "REINICIAR",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionada(s)",
          delete: "Eliminar",
          deleteAria: "Eliminar filas seleccionadas",
        },
      },
    };

    return (
      <MuiThemeProvider theme={darkTheme}>
        <div className={classes.root}>
          <MUIDataTable
            title={title}
            data={data}
            columns={col}
            options={options}
          />
          </div>
      </MuiThemeProvider>
    );
  }

export default DataTable;
import React, { useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay, PictureAsPdf } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useBitacora from "../../hooks/useBitacora";
import UticketDownload from "../UTicketDownload";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginRight: 6,
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const TicketActionButtons = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const { user } = useContext(AuthContext);
  const [downloadTicketModalOpen, setDownloadTicketModalOpen] = useState(false);
  const isMounted = useRef(true);

  const handleOpenTicketOptionsMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTicketOptionsMenu = (e) => {
    setAnchorEl(null);
  };
  const { sendTransferMessage } = useBitacora();
  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
      });
      setLoading(false);
      if (status === "open") {
        history.push(`/tickets/${ticket.id}`);
        await sendTransferMessage(
          ticket.id,
          7, // 5 significa transferido, 6 cerrado, 7 abierto revisar el hook para detalles.
          user?.name,
          null,
          "re-open"
        );
      } else {
        if (status !== "pending") {
          await sendTransferMessage(
            ticket.id,
            6, // 5 significa transferido, 6 cerrado, 7 abierto, 8 pendiente revisar el hook para detalles.
            user?.name,
            null,
            "resolve"
          );
        } else {
          await sendTransferMessage(
            ticket.id,
            8, // 5 significa transferido, 6 cerrado, 7 abierto, 8 pendiente revisar el hook para detalles.
            user?.name,
            null,
            "pending"
          );
        }
        history.push("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };
  const handleOpenDownloadModal = (e) => {
    setDownloadTicketModalOpen(true);
  };
  const handleCloseDownloadTicketModal = () => {
    if (isMounted.current) {
      setDownloadTicketModalOpen(false);
    }
  };

  return (
    <div className={classes.actionButtons}>
      {ticket.status === "closed" && (
        <>
          <ButtonWithSpinner
            loading={loading}
            startIcon={<Replay />}
            size="small"
            onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
          >
            {i18n.t("messagesList.header.buttons.reopen")}
          </ButtonWithSpinner>
          <IconButton color="primary" onClick={handleOpenDownloadModal}>
            <PictureAsPdf />
          </IconButton>
          <UticketDownload
            modalOpen={downloadTicketModalOpen}
            onClose={handleCloseDownloadTicketModal}
            ticketid={ticket.id}
          />
        </>
      )}
      {ticket.status === "open" && (
        <>
          <ButtonWithSpinner
            loading={loading}
            startIcon={<Replay />}
            size="small"
            onClick={(e) => handleUpdateTicketStatus(e, "pending", null)}
          >
            {i18n.t("messagesList.header.buttons.return")}
          </ButtonWithSpinner>
          <ButtonWithSpinner
            loading={loading}
            size="small"
            variant="contained"
            color="primary"
            onClick={(e) => handleUpdateTicketStatus(e, "closed", user?.id)}
          >
            {i18n.t("messagesList.header.buttons.resolve")}
          </ButtonWithSpinner>
          <IconButton color="primary" onClick={handleOpenDownloadModal}>
            <PictureAsPdf />
          </IconButton>
          <UticketDownload
            modalOpen={downloadTicketModalOpen}
            onClose={handleCloseDownloadTicketModal}
            ticketid={ticket.id}
          />
          <IconButton color="primary" onClick={handleOpenTicketOptionsMenu}>
            <MoreVert />
          </IconButton>
          <TicketOptionsMenu
            ticket={ticket}
            anchorEl={anchorEl}
            menuOpen={ticketOptionsMenuOpen}
            handleClose={handleCloseTicketOptionsMenu}
          />
        </>
      )}
      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            {ticket.status === "pending" && (
              <div>
                <ButtonWithSpinner
                  loading={loading}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
                >
                  {i18n.t("messagesList.header.buttons.accept")}
                </ButtonWithSpinner>
                <IconButton color="primary" onClick={handleOpenDownloadModal}>
                  <PictureAsPdf />
                </IconButton>
                <UticketDownload
                  modalOpen={downloadTicketModalOpen}
                  onClose={handleCloseDownloadTicketModal}
                  ticketid={ticket.id}
                />
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default TicketActionButtons;

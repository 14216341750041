import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import DocumentPDF from "./document";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { i18n } from "../../translate/i18n";

const UTicketDownload = ({
  modalOpen,
  onClose,
  ticketid,
}) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
      <DialogTitle id="form-dialog-title">
        {i18n.t("uTicketDownload.title") + " " + ticketid}
      </DialogTitle>
      <PDFViewer style={{ width: "100%", height: "90vh" }}>
        <DocumentPDF ticketId={ticketid} />
      </PDFViewer>
      <form>
        <DialogActions>
          <Button color="primary" variant="outlined">
            <PDFDownloadLink
              style={{ textDecoration: "None" }}
              document={<DocumentPDF ticketId={ticketid} />}
              fileName={`Ticket Nro ` + ticketid + `-backup.pdf`}
            >
              {({ loading }) =>
                loading
                  ? "..."
                  : `${i18n.t("uTicketDownload.buttons.download")}`
              }
            </PDFDownloadLink>
          </Button>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="outlined"
          >
            {i18n.t("transferTicketModal.buttons.cancel")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default UTicketDownload;

import React, { useState, useEffect, useReducer, useRef } from "react";
import {
  Text,
  Page,
  Link,
  View,
  Image,
  Document,
  StyleSheet
} from "@react-pdf/renderer";
import logo from "../../assets/logo.png";
import noProfile from "../../assets/no-profile.png";
import { isSameDay, parseISO, format } from "date-fns";
import clsx from "clsx";

/*Media Type designs */
import VcardPreview from "../VcardPreview";

import ModalImageCors from "../ModalImageCors";
import api from "../../services/api";
import toastError from "../../errors/toastError";

/** Conexion al socket*/
const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }
};
const DocumentPDF = ({ ticketId }) => {
  const [messagesList, dispatch] = useReducer(reducer, []);
  /**CSS para mensajes */
const classes = StyleSheet.create({
  messagesList: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
  },
  textContentItem: {
    overflowWrap: "break-word",
    padding: "6px 6px 6px 6px",
  },
  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },
  messageRightPrivate: {
    alignSelf: "flex-start",
    backgroundColor: "#202C33",
    color: "#e9edef",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding:"5px 5px 5px 5px",
    margin:"2px 0px 0px 0px",
    whiteSpace: "pre-wrap",
  },
  /**Mensajes de respuesta */
  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },
  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },
  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },
  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },
  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },
  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },
  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
  /**Fechas y horas */
  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
  },
  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },
  /**Archivos se meten en el modulo de reactPDf, limitantes con CSS.*/
  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },
  messageMedia: {
    maxWidth: 200,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  /**Pagina y cabezera */
  page: {
    backgroundColor: "white",
    padding: 20,
  },
  messageList: {
    justifyContent: "center",
    marginBottom: 20,
  },
  pageNumber: {
    position: "absolute",
    bottom: 30,
    right: 20,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    color: "white",
    padding: 5,
    borderRadius: 5,
    fontSize: 10,
  },
  /**Nuevo diseño */
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 20,
  },
  rightColumn: {
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-end",
  },
  logo: {
    width: 120,
    height: 50,
  },
  clientCard: {
    flexDirection: "row",
    alignItems: "center",
  },
  clientImage: {
    width: 80,
    height: 80,
    marginRight: 10,
  },
  clientInfo: {
    flexDirection: "column",
    alignItems: "flex-start", // Align text to start of column
  },
  transferMessage: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "100%",
    borderRadius: "15px",
    marginTop: 5,
  },
});

  const lastMessageRef = useRef();
  const currentTicketId = useRef(ticketId);
  const [contact, setContact] = useState({});
  
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        try {
          const { data } = await api.get("/download/" + ticketId);
          setContact(data.ticket.contact);
          if (currentTicketId.current === ticketId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
          }
        } catch (err) {
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [ticketId]);

  const checkMessageMedia = (message) => {
    if (
      message.mediaType === "location" &&
      message.body.split("|").length >= 2
    ) {
      let locationParts = message.body.split("|");
      //let imageLocation = locationParts[0];
      let linkLocation = locationParts[1];

      let descriptionLocation = null;

      if (locationParts.length > 2)
        descriptionLocation = message.body.split("|")[2];

      return (
            <div style={classes.downloadMedia}>
              <Link src={linkLocation}>Ver Direccion</Link>
              <Text>{descriptionLocation}</Text>
            </div>
      );
    } else if (message.mediaType === "vcard") {
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      return <VcardPreview contact={contact} numbers={obj[0].number} />;
    } else if (message.mediaType === "image") {
      return (
          <div className={classes.downloadMedia}>
          <Image style={classes.messageMedia} src={message.mediaUrl}></Image>
          <Link style={{marginTop:10}} src={message.mediaUrl}>Ver imagen</Link>
        </div>
      );
    } else if (message.mediaType === "audio") {
      return (
          <div className={classes.downloadMedia}>
            <Link style={{marginTop:10}} src={message.mediaUrl}>Escuchar audio</Link>
          </div>
      );
    } else if (message.mediaType === "video") {
      return (
          <div className={classes.downloadMedia}>
            <Link style={{marginTop:10}} src={message.mediaUrl}>Ver video</Link>
          </div>
      );
    } else {
      return (
          <div className={classes.downloadMedia}>
          <Link style={{marginTop:10}} src={message.mediaUrl}>Descargar archivo</Link>
          </div>
      );
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span style={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
          <div style={classes.dailyTimestampText}>
            <Text>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </Text>
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span style={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
            <div style={classes.dailyTimestampText}>
              <Text>
                {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
              </Text>
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderQuotedMessage = (message) => {
    return (
      <div
        stylclassName={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              <Text>{message.quotedMsg?.contact?.name}</Text>
            </span>
          )}
          {message.quotedMsg.mediaType === "audio" && (
              <div className={classes.downloadMedia}>
            <Link style={{marginTop:10}} src={message.quotedMsg.mediaUrl}>Escuchar audio</Link>
          </div>
          )}
          {message.quotedMsg.mediaType === "video" && (
             <div className={classes.downloadMedia}>
             <Link style={{marginTop:10}} src={message.quotedMsg.mediaUrl}>Ver video</Link>
           </div>
          )}
          {message.quotedMsg.mediaType === "application" && (
             <div className={classes.downloadMedia}>
             <Link style={{marginTop:10}} src={message.quotedMsg.mediaUrl}>Descargar archivo</Link>
             </div>
          )}

          {message.quotedMsg.mediaType === "image" ? (
           <div className={classes.downloadMedia}>
           <Image style={classes.messageMedia} src={message.quotedMsg.mediaUrl}></Image>
           <Link style={{marginTop:10}} src={message.quotedMsg.mediaUrl}>Ver imagen</Link>
         </div>
          ) : (
            <Text> {message.quotedMsg?.body}</Text>
          )}
        </div>
      </div>
    );
  };

  const renderMessages = () => {
    let boxStyle = "";
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        //No renderizar mensajes privados Return VACIO
        if (message.isPrivate && message.ack === 0) {
          return "";
        }
        //Si los mensajes son privados y es un mensaje de transferencia Bitacora
        if (message.isPrivate && message.ack >= 5) {
          const transfer = {
            5: { color: "#1E90FF", marginRight: "5px" }, // send
            6: { color: "black", marginRight: "5px" },    // close
            7: { color: "#05D164", marginRight: "5px" },  // open
            8: { color: "#FFA500", marginRight: "5px" },  // pending
            9: { color: "#572364", marginRight: "5px" },  // view
          };
          const transferColor = transfer[message.ack] || { color: 'inherit', marginRight: "5px" };

          /**Transferencia de tickets */
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              <View key={`ticket-${message.id}`} wrap={true}>
                <span
                  style={classes.transferMessage}
                  key={`timestamp-${message.id}`}
                >
                  <div style={classes.dailyTimestampText}>
                    <span style={transferColor}>
                      <Text>{message.body}</Text>
                    </span>
                    <span>
                      <Text style={classes.dailyTimestampText}>
                        {format(parseISO(message.createdAt), "HH:mm")}
                      </Text>
                    </span>
                  </div>
                </span>
              </View>
            </React.Fragment>
          );
        }
        //Si los mensajes son o no enviados por mi
        if (message.fromMe) { //mensaje izquierda.
          boxStyle = { 
            alignSelf: "flex-end",
            backgroundColor: "#dcf8c6",
            color: "#303030",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            padding:"5px 5px 5px 5px",
            margin:"2px 0px 0px 0px",
            whiteSpace: "pre-wrap",
        };}else{ // Derecha
          boxStyle = {
          alignSelf: "flex-start",
          backgroundColor: "#E1F3FB",
          color: "#303030",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          padding:"5px 5px 5px 5px",
          margin:"2px 0px 0px 0px",
          whiteSpace: "pre-wrap",
        };}
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              <div style={message.isPrivate ? classes.messageRightPrivate : boxStyle}>
                <div style={classes.textContentItem}>
                {(message.mediaUrl || message.mediaType === "location" || message.mediaType === "vcard") 
                  && checkMessageMedia(message)}
                  {message.quotedMsg && renderQuotedMessage(message)} 
                  <Text>{message.body}</Text>
                  <div style={classes.timestamp}>
                    <Text>{format(parseISO(message.createdAt), "HH:mm")} </Text>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })
      return viewMessagesList;
    } else {
      return <div>Aun no se han enviado mensajes</div>;
    }
  };
  return (
    <Document>
      <Page size="A4" style={classes.page}>
        <View style={classes.header}>
          <View style={classes.clientCard}>
            {contact.profilePicUrl && (
              <Image style={classes.clientImage} src={contact.profilePicUrl} />
            )}
            {contact.profilePicUrl === null && (
              <Image style={classes.clientImage} src={noProfile} />
            )}
            <View style={classes.clientInfo}>
              <Text>{contact.name}</Text>
              <Text>Ticket: {currentTicketId.current}</Text>
              <Text>+{contact.number}</Text>
            </View>
          </View>
          <View style={classes.rightColumn}>
            <Image style={classes.logo} src={logo} />
          </View>
        </View>
        <div className={classes.messageList}>
          {messagesList.length > 0 ? renderMessages() : []}
        </div>
        <Text
          style={classes.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
export default DocumentPDF;
